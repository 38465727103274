
import { defineComponent } from 'vue'
import { Discount } from '@/shared/interfaces/discount.model'

export default defineComponent({
  computed: {
    trial () {
      return this.$store.state.discounts ? this.$store.state.discounts.find((discount: Discount) => discount.trial_weeks && discount.trial_weeks > 0) : null
    }
  },
  methods: {
    scrollToFAQ () {
      const faq = document.getElementById('faq')
      if (faq) {
        faq.scrollIntoView({ behavior: 'smooth' })
      }
    }
  }
})
